@import '../../assets/scss/theme-light.scss';

.homebanner_tit65 {
    font-weight: 500;
    font-size: 65px;
    line-height: 83px;
    color: $text-black;
    position: relative;
    z-index: 1;
}

.homebanner_tit58 {
    font-weight: 500;
    font-size: 58px;
    line-height: 74px;
    color: $text-black;
}

.loader-react {
    z-index: 99999;
    background-color: #010509ba;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transform: rotateY(360deg);
    transition: transform 5s;
    transform-style: preserve-3d;

    // p {
    //     font-size: 18px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     top: 0;
    //     // margin-top: 7%;
    //     // margin-left: -4%;
    //     color: #fff;
    // }
}

.loading {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

}

.unityInstCnt {


    canvas {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100% !important;
        height: 99.99vh !important;
    }

    img {
        animation: rotation 6s infinite linear;
    }
}

@keyframes rotation {
    from {
        transform: rotateY(0deg);
    }

    to {
        transform: rotateY(360deg);
    }
}