@import './themes.scss';

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */


@import "../css/animate.min.css";

/*Seconday font*/

// %font2 {
//     font-family: $secondary-font;
// }


// custom scroll
.scroller::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
}

.scroller::-webkit-scrollbar-thumb {
    background-color: $primary-color;
    /* outline: 1px solid #1e202a; */
    border-radius: 10px;
}

.scroller::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 4px rgba(0,0,0,0.2); */
    background-color: $body-bg;
}

@-moz-document url-prefix() {
    .scroller {
        scrollbar-width: thin;
        scrollbar-color: $primary-color;
    }
}

.text-black {
    color: $text-black !important;
}

.text-color {
    color: $text-color;
}

// .light-text{
//     color: $light-text;
// }
// .light-text2{
//     color: $light-text2;
// }
.text-gray {
    color: $gray-text;
}

.text-gray2 {
    color: $text-gray2;
}

.text-red {
    color: $text-red !important;
}

.greentxt,
.text-green {
    color: $text-green !important;
}

.text-lightblue {
    color: $text-lightblue;
}

.text-gray1 {
    color: $text-gray;
}

.btn {
    font-size: 16px;
    font-weight: 400;

    &.mw-auto {
        min-width: auto !important;
    }
}

.btn-primary {
    background: $primary-gradient-color;
    color: $text-white;
    border: 1px solid transparent;
    border-radius: 50px;
    padding: 13px 30px;
    line-height: 25px;
    min-width: 150px;
    font-weight: 700;
    font-size: 16px;
    transition: all 0.3s linear;

    img.imgicon {
        filter: brightness(1)invert(1);
    }

    &:hover {
        background: $text-black;
        border-color: $primary-color !important;
        color: $text-white !important;

        img.imgicon {
            filter: brightness(1)invert(1);
        }
    }

    &.btn-lg {
        height: 60px;
    }

}

.btn.btncircle {
    width: 56px;
    height: 56px;
    min-width: auto;
    padding: 0;
}

.btn-outline-primary {
    background: transparent;
    color: $text-black;
    border: 1.5px solid $primary-color;
    border-radius: 50px;
    padding: 12px 30px;
    min-width: 150px;
    line-height: 25px;
    transition: all 0.3s linear;

    img.imgicon {
        filter: none;
    }

    &:hover {
        background: $primary-gradient-color;
        border-color: $primary-color !important;
        color: $text-white !important;

        img.imgicon {
            filter: brightness(1)invert(1);
        }
    }

    &.btn-lg {
        height: 60px;
    }
}

.navbgset .mynavbar {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25)
}

.maincontent {
    min-height: calc(100vh - 343px);
    padding-top: 75.75px;
}

.mainAppcontent {
    min-height: calc(100vh - 62px);
    padding-top: 75.75px;
}

.pageContent {
    padding: 50px 0px 50px;
}

.secpad {
    padding: 50px 0px;
}

.secHead {
    font-size: 26px;
    font-weight: 500;
    // color: $text-black;
    text-align: center;
    margin: 10px auto 35px;
    position: relative;
    // &::after{
    //     content: '';
    //     position: absolute;
    //     width: 28px;
    //     height: 4px;
    //     background: $primary-color;
    //     bottom: -15px;
    //     text-align: center;
    //     left: 0;
    //     right: 0;
    //     margin: 0 auto;
    // }
}

.dropdown-toggle::after {
    display: none;
}

/*Home page*/

.homepage {
    position: relative;
    // &::before{
    // content: '';
    // position: absolute;
    // inset:0;
    // top: -71px;    
    // background-image: url('assets/images/new/banner.png');
    // mix-blend-mode: hard-light;
    // background-repeat: no-repeat;
    // background-position: bottom 0px right 0px;
    // background-size: cover;
    // max-height: 694px;    
    // }
    // &::after {
    //     content: '';
    //     background-color: $dark-bg-0;
    //     position: absolute;
    //     inset: 0;
    //     top: -98px;
    //     z-index: -1;
    //     background-size: cover;
    //     max-height: 694px;
    // }
}

.card.mycard {
    background-color: $secondary-color;
    border: 0;
    border-radius: 50px;
    

    &.supportcard,
    &.border-radius-20 {
        border-radius: 20px;
    }

    &.border-radius-34 {
        border-radius: 34px;
    }

    .kyccard{
        img{
            max-width: 100%;
            max-height: 50%;
            justify-content: center;
        }
    }

}

.bordercard {
    background-color: $text-white;
    border: 1px solid $card-border;
    padding: 15px;
    border-radius: 10px;

    &.borhover:hover {
        border-color: $primary-color;
    }
}

.pointer {
    cursor: pointer;
}

.protect_wizard {
    position: relative;

    &::before {
        content: '';
        background: $primary-gradient-color;
        padding: 10px;
        border-radius: 5px;
        width: 45px;
        height: 49px;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: -8px;
    }

    .protect_icon {
        background: $text-white;
        opacity: 0.8;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        width: 65px;
        height: 71px;
        text-align: center;
        line-height: 71px;
        margin: 8px 12px 25px;
        position: relative;

    }

    .protect_tit {
        font-weight: 500;
        font-size: 20px;
        line-height: 53px;
    }

    .protect_cnt {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $text-lightblue;
    }
}


.cryptoInvest_box {
    background: $text-white;
    border: 1px solid $border-color;
    box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.08);
    border-radius: 50px;
    padding: 18px 45px 18px 18px;
    margin: 0px 5px 20px;
    margin-bottom: 20px;
}

img {
    &.img-w40 {
        width: 40px !important;
        height: 40px;
        border-radius: 50px;
    }

    &.img-w32 {
        width: 32px !important;
        height: 32px;
        border-radius: 50px;
    }

    &.img-w64 {
        width: 64px !important;
        height: 64px;
        border-radius: 50px;
    }

    &.img-w24 {
        width: 24px !important;
        height: 24px;
        border-radius: 50px;
    }

    &.img-w57 {
        width: 57px !important;
        height: 57px;
        border-radius: 50px;
    }

}

.rtlslider {
    .cryptoInvest_box {
        .d-flex {
            direction: ltr;
        }
    }
}

.platform_wizard {
    background: $text-white;
    border: 1px solid $border-color;
    box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 35px;
    margin-bottom: 30px;

    .platform_iconbox1 {
        position: relative;

        &::before {
            content: '';
            background: $primary-gradient-color;
            padding: 10px;
            border-radius: 4px;
            width: 33.04px;
            height: 35.97px;
            position: absolute;
            top: 0;
            left: 0;
            margin-top: -8px;
        }

        .platform_icon {
            background: $text-white;
            opacity: 0.8;
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            width: 48.33px;
            height: 52.79px;
            text-align: center;
            line-height: 52.79px;
            margin: 8px 8px 25px;
            position: relative;

        }

    }

    .platform_tit {
        font-weight: 500;
        font-size: 20px;
        line-height: 53px;
    }
}

.cvrage_wizard {
    background: $text-white;
    border: 1px solid $border-color;
    box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    display: flex;
    padding: 10px 20px;
    align-items: center;
    margin-bottom: 15px;

    .cv_unit {
        font-weight: 700;
        font-size: 26px;
        line-height: 53px;
        color: $secondary-dark;
        width: 150px;
        border-right: 1px solid $border-color;
    }

    .cv_type {
        font-weight: 400;
        font-size: 16px;
        padding: 0px 20px;
    }

}


.pt_txt {
    font-size: 30px;
    font-weight: 500;
    color: $text-black;
    text-align: right;
    line-height: 40px;

    span {
        color: $primary-color;
        font-size: 36px;
        font-weight: 700;
    }
}





.socialLinks {
    .aiconLinks {
        display: inline-block;
        margin: 0px 10px;
        color: $text-gray;
        font-size: 18px;

        &:hover {
            color: $primary-color;
        }
    }
}

.mytable {
    thead {
        tr {
            th {
                font-size: 14px;
                font-weight: 400;
                color: $text-gray;
                border-bottom: 1px solid #F2F2F2 !important;
            }
        }
    }

    tbody {
        tr {
            td {
                font-weight: 400;
                font-size: 16px;
                color: $text-black;
                vertical-align: middle;
            }
        }
    }

    &.appTable {
        margin-bottom: 0;

        thead {
            tr {
                th {
                    font-size: 14px;
                    font-weight: 400;
                    color: $text-gray;
                    border-bottom: 1px solid #E2E4EE !important;
                    padding: 10px 20px;
                }
            }
        }

        tbody {
            tr {
                td {
                    font-weight: 400;
                    font-size: 14px;
                    color: $text-black;
                    padding: 10px 20px;
                }
            }
        }
    }

    &.last-borbottom {
        tbody {
            tr:last-child {
                td {
                    border-bottom: 1px solid #E2E4EE !important;
                }
            }
        }
    }
}

.borderbottom {
    border-bottom: 1px solid #E2E4EE;
}


.mypills {
    .nav-pills {
        justify-content: center;
        margin-bottom: 30px;
        border: 1px solid $border-color;
        background-color: $light-bg;
        border-radius: 5px;
        padding: 0px 15px;

        .nav-item {
            margin: 0px 10px;

            .nav-link {
                border: 3px solid transparent;
                color: $text-color;
                font-size: 18px;
                font-weight: 400;
                padding: 10px 10px;
                border-radius: 0;
                position: relative;

                &.active {
                    background: transparent;
                    border-bottom-color: $primary-color;
                    color: $primary-color;
                }
            }
        }
    }
}

.myaccordion {
    .panel {
        .card {
            margin-bottom: 20px;
            border-radius: 0px;
            padding: 0;
            border-color: transparent;

            .card-header {
                background-color: transparent;
                // border: 0;
                // border-bottom: 1px solid $border-color;
                padding: 0;
                border: 1px solid #E2E4EE;
                border-radius: 50px;

                &:hover {
                    border-color: $primary-color;
                }

                button {
                    padding: 1.5rem 1.5rem;
                    font-size: 16px;
                    font-weight: 600;
                    color: $text-black;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .tit-text {
                        max-width: 90%;
                        text-align: left;
                        margin-left: 15px;
                    }

                    .float-right {
                        color: $text-gray2;
                        margin-left: 0;
                    }

                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }
            }

            .card-body {
                padding: 15px 0rem 15px 4rem;
                font-weight: 400;
                font-size: 16px;
                line-height: 29px;
                color: $text-gray;
                position: relative;

                p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 29px;
                    color: $text-gray;
                }

                &::before {
                    content: '';
                    border-left: 1px solid #E5DEFF;
                    display: block;
                    position: absolute;
                    top: -28px;
                    bottom: 0;
                    left: 36px;
                }

                &::after {
                    content: '';
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%)
                        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
                        , radial-gradient(65.28% 65.28% at 26.39% 20.83%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%)
                        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
                        , #805CFF;
                    position: absolute;
                    bottom: 0;
                    left: 34.5px;
                }

            }
        }

        &.panel-open {
            .card {
                border-color: transparent;

                .card-header {
                    border-color: $primary-color;

                    button {
                        // color: $primary-color;
                        // .float-left {
                        // border-bottom: 1px solid $primary-color;
                        //  }
                        // .float-right {
                        // color: $text-color-50;
                        //}

                    }
                }
            }
        }
    }

    &.faqmyaccordion {
        .panel {
            .card {
                border-radius: 10px;
                padding: 0px 0px;
            }
        }
    }

    &:hover {
        border-color: $primary-color;
    }
}

.formInputs {

    label {
        font-size: 15px;
        font-weight: 400;
    }

    .col-form-label {
        font-size: 16px;
        font-weight: 600;
    }

    .form-control {
        height: calc(1.6em + 1.6rem + 2px);
        background-color: $text-white;
        border-color: $border-color;
        box-shadow: none !important;
        color: $text-black;
        font-size: 14px;
        font-weight: 400;
        border-radius: 50px;

        &:focus {
            border-color: $primary-color;
            background-color: transparent;
            box-shadow: none !important;
            color: $text-color;
        }

        &::placeholder {
            color: #b1b4b2;
        }

        &.form-control-lg {
            height: 52px;
        }

        &.roundedInput {
            border-radius: 30px;
        }

    }

    .input-group {
        padding: 7px;

        .input-group-text {
            background-color: transparent;
            border-color: transparent;
            color: $text-black;
        }

        &.iconinput {
            background-color: $text-white;
            border: 1px solid $border-color;
            border-radius: 50px;

            .form-control {
                border-color: transparent;
                border-radius: 0px;
                background-color: transparent;
                border-left-color: $border-color;
                height: 34px;
            }
        }

        &:focus-within {
            border-color: $primary-color;
            background-color: $secondary-color;
        }
    }

    textarea {
        height: auto !important;
        resize: none;
    }

    select {
        appearance: none;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgb(8 0 34)" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
        background-position: center right 15px;
        background-repeat: no-repeat;
        padding-right: 40px;
    }
}

.formInputs {
    .iconinput {
        img {
            max-width: 20px;
            max-height: 20px;
            margin-right: 5px;
            cursor: pointer;
        }

        align-items: center;
    }
}

.custom-switch {
    .custom-control-label::after {
        background-color: $text-white;
        top: calc(0.15rem + 2px);
        left: calc(-2.25rem + 2px);
        width: calc(1.25rem - 4px);
        height: calc(1.25rem - 4px);
    }

    .custom-control-label::before {
        left: -2.35rem;
        width: 2.75rem;
        height: 1.25rem;
        pointer-events: all;
        border-radius: 3rem;
        border-color: #E6E6E6;
        background-color: #E6E6E6;
    }

    .custom-control-input:checked~.custom-control-label::before {
        border-color: #E6E6E6;
        background-color: #E6E6E6;
    }

    .custom-control-input:checked~.custom-control-label::after {
        background: $primary-gradient-color;
        -webkit-transform: translateX(0.75rem);
        transform: translateX(1.25rem);
    }
}

.custom_checkbox {
    margin-bottom: 0px;

    &.custom-checkbox {
        .custom-control-label {
            font-size: 15px;
            font-weight: 400;
            color: $text-gray2;
            padding-top: 0px;
            padding-left: 5px;

            &::before,
            &::after {
                //   border-radius: 0px;
                //   background-color: $text-white;
                //   border: 1px solid $border-color;
                //   border-radius: 2px;
                width: 19px;
                height: 19px;
                top: 0.1rem;
                background: #EDEDED;
                border: 1px solid #A6A6A6;
                border-radius: 3px;
            }
        }

        .custom-control-input:checked~.custom-control-label {
            color: $text-black;

            &::after {
                background-image: url("../images/check2.svg");
                border-color: $primary-color;
                background-size: cover;
                // top: 4px;
                // left: -1.45rem;
            }
        }
    }
}


.icon_wizard {
    position: relative;

    &::before {
        content: '';
        background: $primary-gradient-color;
        padding: 5px;
        border-radius: 5px;
        width: 21px;
        height: 23px;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: -5px;
    }

    .iconbox {
        background: $text-white;
        opacity: 0.8;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        width: 30px;
        height: 33px;
        text-align: center;
        line-height: 33px;
        margin: 4px 5px 5px;
        position: relative;

    }
}

.green_sm_cir {
    width: 8px;
    height: 8px;
    background: #01A94E;
    border-radius: 10px;
    display: inline-block;
}

.overlaybg {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: rgba(75, 71, 94, 0.72);
    opacity: 0.7;
    backdrop-filter: blur(10px);
    z-index: 9999;
    display: none;

    &.overlay_show {
        display: block;
    }
}

.rightSide {
    position: fixed;
    right: -700px;
    top: 0;
    bottom: 0;
    background-color: $text-white;
    height: 100vh;
    width: 650px;
    padding: 60px 60px 60px 30px;
    z-index: 10000;
    transition: right 0.8s cubic-bezier(0.19, 1, 0.22, 1);

    &.RS_open {
        right: 0;
    }

    @media (max-width: 767px) {
        width: 90%;
    }
}

.secondaryborder {
    background: $secondary-color;
    border: 1px solid transparent;
    border-radius: 50px;
    padding: 15px 30px;
}

.pro_img {
    position: relative;
    width: 152px;
    height: 152px;
    margin: 0 auto;

    &>img {
        width: 152px;
        height: 152px;
        border-radius: 50%;
    }
}

.hiddenInputfile {
    opacity: 0;
    width: 0;
    height: 0;
}

.uploadprofile {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: $secondary-color;
    margin: 0;
    text-align: center;
    line-height: 42px;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
}


.mymodal {
    .modal-content {
        background-color: $text-white;
        border: 1px solid #E2DAFF;
        box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.08);
        border-radius: 10px;

        .modal-header {
            border-bottom: 0px solid rgba(0, 0, 0, 0.04) !important;
            padding-bottom: 8px !important;
            align-items: center;
        }

        .mymodaltitle {
            font-size: 20px;
            font-weight: 500;
            color: $text-black;
            text-align: left;
            //   padding: 0px 15px;
            //   margin: 0 auto;
        }

        .close {
            color: $text-color;
            opacity: 1;
            padding: 1rem 1rem 1rem 0;
            margin-left: 0px;
        }
    }

    // &.login_signupModal {
    //   .modal-content {
    //     .close {
    //       color: $text-gray2;
    //       opacity: 1;
    //       padding: 1rem 1rem 1rem 0;
    //       margin-left: 0px;
    //       position: absolute;
    //       z-index: 2;
    //       right: 2px;
    //     }
    //     .modal-body {
    //       padding: 15px 30px 25px;
    //     }
    //   }
    // }
}


.cus_pagination {
    .pagination {
        .page-item {
            .page-link {
                border: 0px;
                font-weight: 400;
                font-size: 14px;
                color: $text-black;
                width: 27px;
                height: 27px;
                line-height: 25px;
                padding: 0px;
                text-align: center;
                margin: 0 5px;

                &:hover {
                    border-radius: 50%;
                    background-color: $primary-color;
                    color: $text-white;
                }
            }

            &:first-child,
            &:last-child {
                .page-link {
                    background: #E2DAFF;
                    border-radius: 2px;
                    // padding: 0.5rem 0.25rem;
                }
            }
        }
    }
}

.cms_head {
    font-weight: 700;
    font-size: 20px;
    line-height: 44px;
    color: $text-black;
    margin-bottom: 10px;
}

.cms_para {
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: $text-gray;

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: $text-gray;
        margin-bottom: 24px;
    }
}





@media (min-width: 1200px) {

    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1150px;
    }
}

@media only screen and (max-width: 1550px) and (min-width: 1200px) {
    .mynavbar .nav-item {
        margin: 0px !important;
    }
}

@media only screen and (max-width: 1599px) and (min-width: 1025px) {
    .roadmap {
        position: relative;

        .rm_list {
            padding: 25px;
            width: 38.5%;
            position: relative;

            &::before {
                position: absolute;
                left: -36%;
                top: -30px;
            }

            &.mt-n150 {
                margin-top: -150px;
            }

            &.mt-n50 {
                margin-top: -50px;
            }

            &.rm_left {
                margin-right: auto;

                &::before {
                    right: -36.75%;
                    left: auto;
                }
            }

            &.rm_right {
                margin-left: auto;
            }

            .rm_tit {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: $primary-color;
                border-bottom: 1px solid $border-color;
                padding-bottom: 8px;
                margin-bottom: 10px;
            }

            .rm_cont {
                color: $text-color;
                font-size: 14px;
                font-weight: 400;
                line-height: 26px;

                ul {
                    padding-left: 20px;
                }
            }

            &:hover {
                border-color: $primary-color;
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .container {
        max-width: 100%;
    }
}

@media only screen and (max-width: 1024px) {
    .roadmap {
        position: relative;

        &::after {
            left: 5%;
            height: 99%;
        }

        .rm_list {
            padding: 25px;
            width: 80%;
            position: relative;
            margin-bottom: 50px;

            &::before {
                position: absolute;
                left: -19%;
                top: -30px;
            }

            &.mt-n150 {
                margin-top: 0px;
            }

            &.mt-n50 {
                margin-top: 0px;
            }

            &.rm_left {
                margin-right: auto;
                margin-left: 15%;

                &::before {
                    left: -19%;
                    right: auto;
                }
            }

            &.rm_right {
                margin-left: 15%;
            }

            .rm_tit {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: $primary-color;
                border-bottom: 1px solid $border-color;
                padding-bottom: 8px;
                margin-bottom: 10px;
            }

            .rm_cont {
                color: $text-color;
                font-size: 14px;
                font-weight: 400;
                line-height: 26px;

                ul {
                    padding-left: 20px;
                }
            }

            &:hover {
                border-color: $primary-color;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .bg_blursec {
        &.bgtxt {
            &::before {
                font-size: 65px;
            }
        }
    }
}

body.dark-theme {
    background-color: #08031D;
    color: #fff;

    nav.mynavbar {
        background-color: #08031D !important;

        .nav-item .nav-link {
            color: #fff;
        }

        .btnheaderIcon {
            img {
                filter: brightness(1) invert(1);
            }
        }
    }

    .tradepagemain .graph {
        border-color: transparent;
    }

    .tradepagemain .tradecard {
        border: 1px solid #070D2D;
        background: #070D2D;
    }

    .tradepagemain .gridrow>div {
        color: #FFF;
    }

    .tradepagemain .mytabs>.nav.nav-pills .nav-item .nav-link {
        color: #EEE;
    }

    .formInputs .input-group.iconinput {
        background-color: transparent;
        border: 1px solid #212850;
        border-radius: 50px;

        .input-group-text {
            img {
                filter: brightness(1)invert(1);
            }
        }
    }

    .tradepagemain .gridhead {
        border: 1px solid #212850;
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        background-color: transparent;
    }

    .tradepagemain .btn-buy {
        background: rgba(19, 184, 135, 0.2);
    }

    .tradepagemain .btn-sell {
        background: rgba(223, 84, 83, 0.2);
    }

    .scroller::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .customslider .labels label {
        color: #fff;
    }
}

//Progress Bar Loading
.pgLdPrg {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(1, 1, 1, 0.867);
    z-index: 9999999;
    // backdrop-filter: blur(11px);
}